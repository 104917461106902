import React from 'react';
import styled from 'styled-components';
import HomeLineup from '../components/home/HomeLineup';

export default function Explore() {
  return (
    <div className="">
      <div className="spacer" />
      <HomeLineup />
    </div>
  );
}
